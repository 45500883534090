
















import Vue from 'vue';

export default Vue.extend({
  data: () => ({
        visible: false as boolean,
        text: '' as string,
        functionToRunOnAccept: {} as () => void
    }),
  methods: {
      accept() {
        if (this.functionToRunOnAccept) {
          this.functionToRunOnAccept();
        }
        this.visible = false;
        this.$emit('accept');
      },
      cancel() {
        this.visible = false;
        this.$emit('cancel');
      },
      show(confirmationText: string, functionToRunOnAccept: () => void) {
        this.text = confirmationText;
        this.visible = true;
        this.functionToRunOnAccept = functionToRunOnAccept;
      }
    }
});
